import './css/Paslaugos.css';
import Nav from '../Components/navigation/Navigation';

import EurSvg from '../Assets/eur.svg'
import dDiamond from '../Assets/d-diamond.svg'
import Procentas from '../Assets/procentas.svg'
import EgIcon from '../Assets/Brand/egIcon.png'

import gtaLeft from '../Assets/gta-left.png'
import gtaRight from '../Assets/gta-right.png'

import React, { useState } from 'react';
import axios from 'axios';

interface IPaymentModel {
    name: string;
    money: string;
    kredits: string;
    klaida: string;
}

interface IPaymentResponse{
    success: boolean;
    message: string;
    data?: any;
    link: string;
}

function About() {
    const [paymentData, setValue] = useState<IPaymentModel>({ name: "", money: "2", kredits: "100", klaida: ""});
    //const [paymentResponse] = useState<IPaymentResponse>({ Response: ""});

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({ ...paymentData, name: event.target.value });
    };
    const handleMoneyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let mValue = event.target.value;

        let mKredits = Math.floor(parseInt(mValue)*100/2)/*/2*/
        if(Number.isNaN(mKredits)) mKredits = 0;
        if(parseInt(mValue) > 10) mKredits = Math.floor(mKredits * 1.15);
        
        setValue({ ...paymentData, money: mValue, kredits: mKredits.toString(), klaida: "" });
    };

    const handlePurchase = async () => {
        try {
            // /api/Paysera
            // http://localhost:5117/api/Paysera
          const response = await axios.post<IPaymentResponse>("/api/Paysera", {
            ...paymentData
          }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
          });
          if (response.data.success) {
            console.log(`Success ${response.data.message}`)
            openPayLink(response.data.link)
            // Handle successful purchase
          } else {
            console.log(`Fail ${response.data.message}`)
            // Handle failed purchase
          }
        } catch (error) {
          // Handle error
          console.error(error);
        }
    };

    const openPayLink = (link: string) => {
        window.open(link, "_self")
    };

    const submitBuy = () => {
        //console.log(paymentData.name + " " + paymentData.money + " " + paymentData.kredits)
        if(paymentData.name.length < 3 || paymentData.name.length > 24){
            setValue({ ...paymentData, klaida: "Prašome nurodyti teisingą slapyvardį V_P formatu!" });
            return;
        }

        let words = paymentData.name.split('_');
        if(words.length < 2 || words[0].length < 3 || words[1].length < 3){
            setValue({ ...paymentData, klaida: "Prašome nurodyti teisingą slapyvardį V_P formatu!" });
            return;
        }

        let mValue = parseInt(paymentData.money);
        //console.log(mValue)
        if(mValue < 2 || mValue > 1000){
            setValue({ ...paymentData, klaida: "Suma 2-1000!" });
            return;
        }

        // Pradedame pirkimą
        handlePurchase();
    };
    return (
        <div>
            <div className='headeris'>
                <div className="icon icon1"></div>
                <div className="icon icon2"></div>
                <div className="container">
                    <div className="header-layout">
                    <Nav/>

                    </div>
                    <div className="header-services-block">
                        <div className="header-services">
                            <div className="bills">
                                <div className="bills-content">
                                    <div className="title">
                                        <h1>Sąskaitos Papildymas</h1>
                                        {paymentData.klaida !== "" && <h1 style={{ color: "red", fontSize: "16px" }}>{paymentData.klaida}</h1>}
                                    </div>
                                    <div className="input-name-block">
                                        <label>Prisijungimo Vardas </label>
                                        <div className="input-name">
                                            <input
                                                type={'text'}
                                                id="name"
                                                name="name"
                                                maxLength={24}
                                                placeholder="Slapyvardis"
                                                value={paymentData.name}
                                                onChange={handleNameChange}
                                                // v-on:keypress="updateSlapyvardis"
                                                // @paste="onPaste"
                                            />
                                        </div>
                                    </div>

                                    <div className="input-money">
                                        <label>Papildymo suma</label>

                                        <div className="input-money-block d-flex">
                                            <div className="input-money-icon">
                                                <img src={EurSvg} alt="eur" />
                                                <input
                                                    // :style="(this.fields.money < 2 || this.fields.money > 1000) ? 'outline: 2px solid red' : 'outline: none'"
                                                    type="text"
                                                    //id="money"
                                                    //name="money"
                                                    //min="2"
                                                    //max="1000"
                                                    minLength={0}
                                                    maxLength={4}
                                                    placeholder="2"
                                                    value={paymentData.money}
                                                    onChange={handleMoneyChange}
                                                    // @input="updateSuma"
                                                    // @paste="onPaste"
                                                    // @keypress="onlyNumber"
                                                />
                                            </div>
                                            <div className="input-diamond-icon">
                                                <img src={dDiamond} alt="diamond" />
                                                <input
                                                    type="text"
                                                    id="diamond"
                                                    name="diamond"
                                                    placeholder="100"
                                                    min="100"
                                                    max="100000"
                                                    disabled={true}
                                                    value={paymentData.kredits}
                                                    // @input="updateKreditai"
                                                    // @paste="onPaste"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bills-info">
                                    <img src={Procentas} alt="procentas" />
                                    <div className="text">
                                        <p>Gaukite 15% daugiau,</p>
                                        <p>papildydami sąskaita nuo 10 eurų</p>
                                        {/* <p>Nuolaida pritaikoma automatiškai!</p> */}
                                        {/* <p>Kalėdinė akcija - Gauk 50% daugiau kreditų</p>
                                        <p style={{ color: "red"}}>Įvedant sumą akcija jau yra pritaikyta ir rodo galutinę gaunamą kreditų sumą</p> */}
                                    </div>
                                </div>
                                <div className="pagalbatext">
                                    <p>Paslaugų sąrašą rasi: <a href="https://forumas.lsrg.lt/index.php?/topic/423-privilegijos/">Čia</a></p>
                                    <p>Pagalba dėl apmokėjimo problemų: info@lsrg.lt arba Discord: tuk1s</p>
                                    {/* <p className="redAkcija">Akcija kreditams -50% iki 2024-11-03 (Nuolaida jau pritaikyta ir rodo kiek gausite kreditų)</p> */}
                                    {/* <p>Valentinas Olekas Individuali veikla Nr. 1334531</p> */}
                                </div>
                                <button onClick={submitBuy}>Papildyti</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <div className="footer-block">
                    <div className="footer-grid-block left-column footer-img">
                        <img src={gtaLeft} alt="" />
                    </div>
                    <div className="footer-grid-block middle-column">
                        <div className="middle-info">
                            <img src={EgIcon} alt="icon" />
                            <div className="icons">
                                <a href="https://www.facebook.com/LSRGserveris"><i className="fa-brands fa-facebook"></i></a>
                                <a href="https://www.tiktok.com/@lsrglt"><i className="fa-brands fa-tiktok"></i></a>
                                <a href="https://discord.gg/MsW76n4"><i className="fa-brands fa-discord"></i></a>
                                <a href="https://www.youtube.com/channel/UC0lSoA7MHRRZwUWgh9qggkA"><i className="fa-brands fa-youtube"></i></a>
                            </div>
                            <p>© 2024 lsrg.lt</p>
                        </div>
                    </div>
                    <div className="footer-grid-block right-column footer-img">
                        <img src={gtaRight} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About